import { Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router";
import { Get } from "../../actions";
import TableComponent from "../../components/table"
import { Get_AllEmployeeTracks_URL, Get_EmployeeMonthlyReporting_URL } from "../../constants/apiUrls";
import { format } from "../../heplers/format";



export const EmployeeDashboard=()=>{
  const {enqueueSnackbar} =useSnackbar()
    
  const columns = [
    
    {
      // 0 = onNotice, 1 = available, 2 = occupied, 3 = reserve, 4 cancel
      id: "name",
      disablePadding: true,
      label: "Employee ",
    },
    {
      id: "lastActivityDate",
      numeric: false,
      disablePadding: true,
      label: "Last Activity",
      component:({row})=><Typography>{format('dateTime',row.lastActivityDate)}</Typography>
    },
    
  
    {
      id: "todayHours",
      numeric: false,
      disablePadding: true,
      label: "Today",
    },
    {
      id: "lastWeekHours",
      numeric: false,
      disablePadding: true,
      label: "Last Week",

    },
    {
        id: "currentWeekHours",
        numeric: false,
        disablePadding: true,
        label: "Current Week",

      },
      {
        id: "currentMonthHours",
        numeric: false,
        disablePadding: true,
        label: "Current Month",
      },

  ];

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [employeeReports,setEmployeeReports]=useState([])
  const [employeeReportsLoading,setEmployeeReportsLoading]=useState(false)
  const history=useHistory();
  useEffect(()=>{
    loademployeeReports();
  },[])
  const loademployeeReports = async () => {
    setEmployeeReportsLoading(true);
    Get(
      {
      },
      Get_EmployeeMonthlyReporting_URL,
      null,
      (resp) => {
        setEmployeeReportsLoading(false);
        setEmployeeReports(resp?.data);
      },
      (error) => {
        console.log("ajklshdasjkldhas",error)
        setEmployeeReportsLoading(false);
        enqueueSnackbar("Internal server error", {
          variant: "error",
        });
      }
    );
  };

  const handleDetailReporting=(row)=>{
    history.push("/employeeReporting/"+row.id)
  }
    return <TableComponent
    auto
    dense
    colums={columns}
    sortBy="date"
    sortDirection="desc"
    rows={employeeReports}
    page={page}
    rowsPerPage={rowsPerPage}
    handleChangePage={handleChangePage}
    handleChangeRowsPerPage={handleChangeRowsPerPage}
    viewEdit
    onEdit={handleDetailReporting}
  />
}