import React from 'react';
import Upload from "../assests/icons/uploadIcon.png"
import Add from '../assests/icons/addIcon.png'
import Close from '../assests/icons/closeIcon.png'
import Scanner from '../assests/icons/scannerIcon.png'
import Person from "../assests/icons/PersonIcon.png"
import supplierIcon from "../assests/icons/supplierIcon.svg"
import GenerateDueIconSvg from "../assests/icons/generateDues.svg"
import PreviousDueIconSvg from "../assests/icons/previousDues.svg"
import Home from "../assests/icons/home.png"
import Done from '../assests/icons/done.png'
import Wallet from '../assests/icons/wallet.png'
import ReportAllIconSVG from '../assests/icons/reportAllIcon.svg'
import ReportSingleIconSVG from '../assests/icons/reportSingleIcon.svg'
import SendRemindersToAll from '../assests/icons/sendRemindersToAll.png'
import bankSVG from '../assests/icons/bank.svg'
import emailSVG from '../assests/icons/email.svg'

export const UploadIcon = (props) => {
  return <img alt="Logo" src={Upload} {...props} />;
};

export const IconAdd = (props) => {
  return <img alt="Logo" src={Add} {...props} />;
};
export const HomeIcon = (props) => {
  return <img alt="Logo" src={Home} {...props} style={{ height: "15px", width: "15px" }}/>;
};
export const DoneIcon = (props) => {
  return <img alt="Logo" src={Done} {...props} style={{ height: "15px", width: "15px" }}/>;
};
export const WalletIcon = (props) => {
  return <img alt="Logo" src={Wallet} {...props} style={{ height: "15px", width: "15px" }}/>;
};

export const CloseIcon = (props) => {
  return <img alt="close" src={Close} {...props} />;
};

export const IconScanner = (props) => {
  return <img alt="close" src={Scanner} {...props} style={{ height: "40px", width: "40px" }} />;
};

export const PersonIcon = (props) => {
  return <img alt="PersonIcon" src={Person} {...props} />;
};

export const SupplierIcon = (props) => {
  return <img alt="TruckIcon" src={supplierIcon} {...props} />;
};

export const GenerateDueIcon = (props) => {
  return <img alt="TruckIcon" src={GenerateDueIconSvg} {...props} />;
};

export const PreviousDueIcon = (props) => {
  return <img alt="TruckIcon" src={PreviousDueIconSvg} {...props} />;
};

export const ReportAllIcon = (props) => {
  return <img alt="Logo" src={ReportAllIconSVG} {...props} />;
};
export const ReportSingleIcon = (props) => {
  return <img alt="Logo" src={ReportSingleIconSVG} {...props} />;
};
export const ReminderToAll = (props) => {
  return <img alt="Logo" src={SendRemindersToAll} {...props} style={{width:"160px",height:"40px"}}/>;
};

export const BankIcon = (props) => {
  return <img alt="Logo" src={bankSVG} {...props} />;
};

export const EmailIcon = (props) => {
  return <img alt="Logo" src={emailSVG} {...props} />;
};