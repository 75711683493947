import Moment from 'moment';

export function format(formatType, value) {
  if (value) {
    switch (formatType) {
      case "date":
        if (Moment(value).isAfter(Moment("01-01-0001")))
          return Moment(value).format("DD-MM-YYYY");
        return "-";
      case "dateTime":
        if (Moment(value).isAfter(Moment("01-01-0001")))
          return Moment(value).format("DD-MM-YYYY HH:mm");
        return "-";
      case "time":
        if (Moment(value).isAfter(Moment("01-01-0001")))
          return Moment(value).format("LT");
        return "-";
      case "number":
        return typeof value === "number"
          ? value.toFixed(2)
          : typeof value !== "undefined"
          ? value
          : 0.0;
      default:
        return value;
    }
  } else return "-";
  }

export function getWeekdaysInMonth(month, year) {
  var days = daysInMonth(month, year);
  var weekdays = 0;
  for (var i = 0; i < days; i++) {
    if (isWeekday(year, month, i + 1)) weekdays++;
  }
  return weekdays;
}

function isWeekday(year, month, day) {
  var day = new Date(year, month, day).getDay();
  return day != 0 && day != 6;
}

function daysInMonth(iMonth, iYear) {
  return 32 - new Date(iYear, iMonth, 32).getDate();
}
  