import { RiBankFill, RiBuilding2Fill, RiDashboardFill, RiMailAddFill, RiProfileFill, RiProfileLine, RiSettings2Fill } from "react-icons/ri";

const navConfig = [
  {
    subheader: "",
    items: [
      {
        title: "Dashboard",
        icon: RiDashboardFill,
        href: "/employeeDashboard",
      },
      {
        title: "Employee Reporting",
        icon: RiBuilding2Fill,
        href: "/employeeReporting",
      },
      // {
      //   title: "Tanents",
      //   icon: RiProfileLine,
      //   href: "/tanents",
      // },
      // {
      //   title: "Dues",
      //   icon: RiMailAddFill,
      //   href: "/dues/duesGenerated",
      // },
      // {
      //   title: "Bank",
      //   icon: RiBankFill,
      //   href: "/bank",
      // },
      // {
      //   title: "Supplier",
      //   icon: RiProfileFill,
      //   href: "/suppliers",
      // },
      // {
      //   title: "Setting",
      //   icon: RiSettings2Fill,
      //   href: "/setting/email",
      // },

      // {
      //   title: "Account",
      //   icon: RiAccountPinBoxLine,
      //   href: "/account",
      //   items: [
      //     {
      //       title: "My Account",
      //       href: "/account/profile",
      //     },

      //   ],
      // },
    ],
  },
];

export default navConfig;
