import React from 'react';
import TransparentLogoFile from "../assests/images/logo_v2.svg"
import LogoFile from "../assests/images/logo.svg"
import LogoFile1 from "../assests/icons/favIcon.png"


export const TransparentLogo = (props) => {
  return <img alt="Logo" src={TransparentLogoFile} {...props} />;
};

export const Logo = (props) => {
  return <img alt="Logo" src={LogoFile} {...props} />;
};

export const Logo1 = (props) => {
  return <img alt="Logo" src={LogoFile1} {...props} style={{width:"80px",height:"80px"}}/>;
};
