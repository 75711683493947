import { combineReducers } from "redux";
import auth from "./authReducer";
import breadcrumbs from "./breadcrumbReducer";
import submenu from "./submenu";

export default combineReducers({
  auth,
  breadcrumbs,
  submenu
});
