import axios from "../heplers/apiHelper";
import { LOGIN_URL,GET_USER_PROFILE_URL,Post_Logout_URL, Post_RefreshToken_URL, Post_UpdateUSER_PROFILE_URL, Post_UpdateUserPassword_URL } from "../constants/apiUrls";
import { SET_USER_DATA } from "../constants/types";
import authService from "../utils/authUtils"
import { SERVER_URL } from "../constants";
import { validateAuthentication } from ".";


export const userLogin = (payload) => async (dispatch) => {
  try {
    const response = await axios().post(LOGIN_URL, payload);
    dispatch({ type: SET_USER_DATA, payload: response.data.user });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const tryGetRefreshToken = async (axiosRefreshInstance, payload) => {
  try {
    const response = await axios().post(`${Post_RefreshToken_URL}`, payload);
    // dispatch({ type: SET_USER_DATA, payload: response.data });
    return response.data;
  } catch (error) {
    return error.response;
    //onError(error.response)
  }
};
export const getUserData = (onSuccess,onError) => async dispatch => {
  await validateAuthentication("get", async (token) => {
  try {
    const response = await axios(token).get(GET_USER_PROFILE_URL);
    onSuccess(response)
  } catch (error) {
    return onError(error.response);
  }})
}
export const updateUserInfo = (payload, onSuccess,onError) => async dispatch => {
  await validateAuthentication("post", async (token) => {
  try {
    const response = await axios(token).post(Post_UpdateUSER_PROFILE_URL,payload);
    onSuccess(response)
  } catch (error) {
    return onError(error.response);
  }})
}
export const updateUserPassword = (payload, onSuccess,onError) => async dispatch => {
  await validateAuthentication("post", async (token) => {
  try {
    const response = await axios(token).post(Post_UpdateUserPassword_URL,payload);
    onSuccess(response)
  } catch (error) {
    return onError(error.response);
  }})
}

export const Logout=(history)=>async dispatch=>{
  try {
    const response = await axios().post(Post_Logout_URL);
    dispatch({ type: "LOGOUT"});
    return response;
  } catch (error) {
    return error.response;
  }
}

// export const setUserData = (payload) => async (dispatch) => {
//   dispatch({ type: SET_USER_DATA, payload: payload });
// };

