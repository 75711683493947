export const LOGIN_URL = "Auth/Login";
export const Post_Register_URL = "Auth/Register";
export const Post_RefreshToken_URL = "Auth/RefreshToken";
export const GET_USER_PROFILE_URL = "Auth/GetUserProfile";
export const Post_UpdateUSER_PROFILE_URL = "Auth/UpdateUserProfile";
export const Post_UpdateUserPassword_URL = "Auth/ChangePassword";

export const GET_AllUsers_URL = "Auth/GetAllUsersWithPagination";
export const Post_Logout_URL = "Auth/Logout";

// File Manager URL's
export const Post_FileUpload_URL = "FileManager/Upload";
export const Get_File_URL = "FileManager/Get";
export const Get_DeleteFile_URL = "FileManager/DeleteFile";
export const Get_FileInfo_URL = "FileManager/GetFileInfo";

// Reporting
export const Get_AllEmployeeTracks_URL = "ProjectTracking/GetAllTracks";
export const Get_EmployeeMonthlyReporting_URL = "ProjectTracking/MonthlyReport";
export const Get_GetAllWorkHistories_URL = "ProjectTracking/GetAllWorkHistories";
export const Post_AddManualTracking_URL = "ProjectTracking/AddManualTracking";
