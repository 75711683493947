import React, { Suspense, Fragment } from "react";
import { Route, Switch } from "react-router-dom";
// import SplashScreen from "./components/SplashScreen"
import Auth from "./components/Auth";
import AuthGuard from "./components/AuthGuard";
//Website Layouts
import AppLayout from "./layout";

//Auth Views
import Login from "./views/auth/Login";
import ForgetPassword from "./views/auth/ForgetPassword";

//Account Views
import MyAccount from "./views/account/myAccount";
import {EmployeeDashboard, EmployeeReporting} from "./views/employeeReporting";


const routesConfig = [
  {
    path: "/login",
    exact: true,
    component: () => <Login />,
  },
  {
    path: "/forget_password",
    exact: true,
    component: () => <ForgetPassword />,
  },
  {
    path: "/",
    layout: AppLayout,
    guard: Auth,
    //bootstrap: AuthGuard,
    childrens: [
      {
        path: "/",
        exact: true,
        component: () => <EmployeeDashboard />,
      },
      {
        path: "/employeeReporting/:employeeId",
        exact: true,
        component: () => <EmployeeReporting />,
      },
      {
        path: "/employeeDashboard",
        exact: true,
        component: () => <EmployeeDashboard />,
      },

    ],
  },
];

const renderRoutes = (routes) => {
  return routes ? (
    <Suspense fallback={<h1>loading...</h1>}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;
          const Bootstrap = route.bootstrap || Fragment;

          return (
            <Route
              exact={route.exact}
              path={route.path}
              key={i}
              render={(props) => (
                <Layout>
                  <Guard>
                    <Bootstrap>
                      {route.childrens ? (
                        renderRoutes(route.childrens)
                      ) : (
                        <Component {...props} />
                      )}
                    </Bootstrap>
                  </Guard>
                </Layout>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;
};

const AppRoutes = () => {
  return renderRoutes(routesConfig);
};

export default AppRoutes;
