import React, { useEffect, useState } from "react";
import Table from "../../components/table";
import { Get, Post } from "../../actions/apiActions";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { useLocation, useParams } from "react-router";

import { useDispatch } from "react-redux";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  makeStyles,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  Alert,
  Autocomplete,
  ToggleButton,
  ToggleButtonGroup,
} from "@material-ui/lab";
import _ from 'lodash';
import { Get_AllEmployeeTracks_URL, Get_GetAllWorkHistories_URL, Post_AddManualTracking_URL } from "../../constants/apiUrls";
import { GetFile } from "../../actions/apiActions";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { DatePicker } from "../../components/DatePicker";
import { Collapser } from "../../components/Collapser";
import { DeleteButton } from "../../components/ButttonsWithIcons";
import { format } from "../../heplers/format";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import ButtonWithLoading from "../../components/ButtonWithLoading";
import moment from "moment";

//name, address, zipcode, Town
const columns = [
  {
    id: "actions",
    label: "Action",
    align: "left",
  },

  {
    id: "counterpartName",
    numeric: true,
    disablePadding: true,
    label: "Counter Part Name",
  },
  {
    id: "amount",
    align: "right",
    label: "Amount",
    format: "number",
  },
  {
    id: "valueDate",
    numeric: false,
    disablePadding: true,
    label: "Date",
    format: "date",
  },
];


const useStyles = makeStyles({
  root: {
   // background: "linear-gradient(45deg, #9013FE 15%, #50E3C2 90%)",
    minWidth: "100%",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  card: {
    maxWidth: "40%",
    minHeight: "20vh",
    display: "flex",
    alignItems: "center"
  }
});

const Bank = () => {

  const {employeeId} = useParams();

  const {enqueueSnackbar} =useSnackbar()
  const [page,setPage]=useState(0)
  const [rowsPerPage,setRowsPerPage]=useState(10)
  const [employeeReports,setEmployeeReports]=useState([])
  const [employeeReportsLoading,setEmployeeReportsLoading]=useState(false)
  const [imageAddress,setImageAddress]=useState()
  var today = new Date();
  const [from,setFrom]=useState(today)
  const [to,setTo]=useState(today)

  const [open,setOpen]=useState()
  const classes = useStyles();

  useEffect(()=>{
    loadmployeeReports();
  },[from,to])
  const loadmployeeReports = async () => {
    setEmployeeReportsLoading(true);
    Get(
      {
        from:moment(from).format("MM-DD-YYYY"),
        to:moment(to).format("MM-DD-YYYY"),
        userId:employeeId
      },
      Get_GetAllWorkHistories_URL,
      null,
      (resp) => {
        setEmployeeReportsLoading(false);
        setEmployeeReports(resp?.data);
      },
      (error) => {
        console.log("ajklshdasjkldhas",error)
        setEmployeeReportsLoading(false);
        enqueueSnackbar("Internal server error", {
          variant: "error",
        });
      }
    );
  };
  //handlers
 
  const openDialog=(imageAddress)=>{
    setImageAddress(imageAddress);
    setOpen(true)
  }

  const closeDialog=()=>{
    setOpen(false)
    setImageAddress(null);
  }
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('lg');

  const onDeleteTrack=(track)=>{
    
  }

  const handleAddManualHours=(values,actions)=>{
    Post(values,
    Post_AddManualTracking_URL,
    null,
    resp=>{
      enqueueSnackbar("Manual hours added",{variant:'success'})
      actions.setSubmitting(false);
      var index=employeeReports.findIndex(x=>x.id==values.workHistoryId);
      var item=employeeReports[index];
      item.manualWorkingMinutes=resp.data.manualWorkingMinutes
      item.manualWorkingHoursText=resp.data.manualWorkingHoursText
      item.totalWorkingHoursText=resp.data.totalWorkingHoursText
      item.totalWorkingMinutes=resp.data.totalWorkingMinutes
      item.workingMinutes=resp.data.workingMinutes
      item.workingMinutesText=resp.data.workingMinutesText
      var newArr=employeeReports;
      newArr[index]=item;
      setEmployeeReports([...newArr]);
    },
    error=>{
      enqueueSnackbar("Failed to add manual hours, Try again",{variant:'error'})
      actions.setSubmitting(false);
    }
    )
  }

  const hourFormat = (total_minutes) => {
    const hours = parseInt(total_minutes / 60);
    const minutes = parseInt(total_minutes % 60);
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
  }
  return (<>
    <Card>
      <CardHeader title="Asghar khan"/>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item md={4} lg={4}>
          <DatePicker 
          label="From"
          value={from}
          onChange={setFrom}
          />
          </Grid>
          <Grid item md={4} lg={4}>
          <DatePicker 
          label="To"
          value={to}
          onChange={setTo}
          />
          </Grid>
          
        </Grid>
      
        <br/>
        <br/>
       
      </CardContent>
    </Card>
    <Card style={{ marginTop: "30px" }}>
      <CardContent style={{ marginLeft: "50px" }}>
        <Typography>Total: {hourFormat(_.sumBy(employeeReports,x=>x.totalWorkingMinutes))} hrs</Typography>
        <div style={{marginTop:'20px',display:'inline-block'}}>
          <Box style={{backgroundColor:'lightgreen',width:'14px',height:'14px',display:'inline-block'}} ></Box>
          <Typography style={{display:'inline-block',marginLeft:'10px'}}>  Tracked: {hourFormat(_.sumBy(employeeReports,x=>x.workingMinutes))} hrs</Typography>
          <Box style={{backgroundColor:'#F2BE04',width:'14px',height:'14px',display:'inline-block',marginLeft:'20px'}} ></Box>
          <Typography style={{display:'inline-block',marginLeft:'10px'}}>  Manual: {hourFormat(_.sumBy(employeeReports,x=>x.manualWorkingMinutes))} hrs</Typography>
        </div>
      </CardContent>
    </Card>
    <Box style={{marginTop:"30px"}}>
    {employeeReportsLoading ?
          <Grid
            className={classes.root}
            spacing={0}
            alignItems="center"
            justify="center"
          >
            <CircularProgress />
          </Grid>
          :
          <>
            {_.sortBy(employeeReports, x => x.startDate).map(value => {
              // const totalMinutes=_.sumBy(value,x=>x.minutes);
              // const hrs=Math.floor(totalMinutes/60);
              // const minutes=totalMinutes%60;
              console.log("ajdghkkasdadgas", value)
              return <Collapser style={{ marginTop: "20px" }} title={<><label>{value.startDate} ----- {value.projectName} ----- {value.totalWorkingHoursText} Total & {value.manualWorkingHoursText} manual hrs</label></>}>
                <Grid container spacing={2}>
                  <Grid item style={{marginBottom:"50px"}} sm={12} md={12} lg={12}>
                    <Formik
                      initialValues={{
                        hours: 0,
                        minutes: 0,
                        workHistoryId: value.id
                      }}
                      onSubmit={(values, actions) => {
                        actions.setSubmitting(true);
                        if (values.hours == 0 && values.minutes == 0) {
                          actions.setSubmitting(false);
                          actions.setErrors({ hours: "Required", minutes: "Required" })
                          return;
                        }
                        handleAddManualHours(values, actions)
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                      }) => (
                        <Form >
                          <Grid container spacing={2}>
                            <Grid item>
                              <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="email"
                                label="Hours"
                                name="hours"
                                autoFocus
                                size="small"
                                value={values.hours}
                                onChange={handleChange}
                                error={touched.hours && Boolean(errors.hours)}
                                helperText={touched.hours && errors.hours}
                              />
                            </Grid>
                            <Grid item>
                              <TextField
                                variant="outlined"
                                required
                                fullWidth
                                size="small"
                                name="minutes"
                                label="Minutes"
                                type="number"
                                id="minutes"
                                value={values.minutes}
                                onChange={handleChange}
                                error={touched.minutes && Boolean(errors.minutes)}
                                helperText={touched.minutes && errors.minutes}
                              />
                            </Grid>
                            <Grid item>
                              <ButtonWithLoading
                                type="submit"
                                title="Add manual working hours"
                                fullWidth
                                variant="contained"
                                loading={isSubmitting}
                                onClick={handleSubmit}
                              />
                            </Grid>
                          </Grid>
                        </Form>
                      )}
                    </Formik>
                  </Grid>

                  {value.tracks.map((track, index) => <Track onClick={openDialog} track={track} onDelete={onDeleteTrack} />)}
                </Grid>
              </Collapser>
            })}
          </>
        }
    </Box>
    <Dialog open={open} fullScreen={fullScreen}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      onClose={closeDialog}
      >
        <DialogContent>
          <Box width={1}>
          <img src={GetFile(imageAddress)}/>
          </Box>

        </DialogContent>
        <DialogActions>
          <button onClick={closeDialog}>Close</button>
        </DialogActions>
      </Dialog>
    </>);
};
export default Bank;

const Track=({track,onClick,onDelete})=>{
  return <Grid item md={4} sm={4} lg={3}><Box>
    <label>{format( "time", track.startTime)}   -   {format("time", track.endTime)}</label>
    <DeleteButton onClick={()=>onDelete(track)} />
    <label style={{float:"right"}}>{track.activityLevel} %</label>

    <br/>
    <img onClick={()=>onClick(track.imageAddress)} src={GetFile(track.imageAddress)} height="200px" width="100%"/>
  </Box></Grid>  
}
