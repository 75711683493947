import React, { useState } from "react";
import {
  AppBar,
  Box,
  Typography,
  Button,
  Toolbar,
  Avatar,
  Menu,
  IconButton,
  MenuItem,
} from "@material-ui/core";
import {useSelector,useDispatch} from "react-redux"
import { withRouter } from "react-router-dom";
import { HiOutlineLogout, HiUserCircle } from "react-icons/hi";
import useStyles from "../../assests/styles/layout/topbar";
import SubMenus from './submenu'
import {Logout} from '../../actions'
import authUtils from "../../utils/authUtils";
import clsx from 'clsx';
import MenuIcon from '@material-ui/icons/Menu';

function TopBar({ history, className, navOpen,handleDrawerOpen, ...rest }) {
  const dispatch = useDispatch();
  const classes = useStyles();

  var [profileMenu, setProfileMenu] = useState(null);

  const {user} = useSelector(state=>state.auth)
  const handleNavigateToProfile = () => {
    history.push("/account/profile");
    setProfileMenu(null);
  };

  const handleLogout = () => {
      dispatch(Logout()).then((resp)=>{
        authUtils.logout();
      history.push("/login");
    });
  };

  return (
    <AppBar  className={clsx(classes.root, {
      [classes.rootshift]: !navOpen,
    })} {...rest}>
      <Toolbar className={classes.toolbar}>
      <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, navOpen && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <SubMenus/>
          <IconButton
            aria-haspopup="true"
            aria-controls="profile-menu"
            variant="circular"
            className={classes.avatar}
            onClick={(e) => {
              setProfileMenu(e.currentTarget);
            }}
          >
            {user?.firstName?.slice(0, 1)}
            {user?.lastName?.slice(0, 1)}
          </IconButton>

          <Menu
            open
            id="profile-menu"
            open={Boolean(profileMenu)}
            anchorEl={profileMenu}
            onClose={() => setProfileMenu(null)}
            disableAutoFocusItem
            className={classes.menuBar}
            MenuListProps={{ className: classes.profileMenuList }}
          >
            <MenuItem>
              <Box
                onClick={handleNavigateToProfile}
                display="flex"
                alignItems="center"
              >
                <HiUserCircle className={classes.icon} />
                <Typography variant="body1">My Account</Typography>
              </Box>
            </MenuItem>
            <MenuItem>
              <Box display="flex" alignItems="center" onClick={handleLogout}>
                <HiOutlineLogout className={classes.icon} />
                <Typography variant="body1">Logout</Typography>
              </Box>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default withRouter(TopBar);
